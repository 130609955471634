@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.scrollbar::-webkit-scrollbar,
html::-webkit-scrollbar {
  width: 5px;
}

.scrollbar::-webkit-scrollbar-track,
html::-webkit-scrollbar-track {
  background-color: #1b1b1b;
}

.scrollbar::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
  background-color: #555555;
  border-radius: 10px;
}

.productCardScrollBar {
  scrollbar-width: thin;
  scrollbar-color: var(--ring) var(--background);
}

html {
  scroll-behavior: smooth;
}

.slick-dots {
  bottom: 1rem !important;
  @apply left-2/4 z-50 flex -translate-x-2/4 gap-2;
  * {
    @apply transition-all;
  }

  li {
    width: auto !important;
  }
}

.strike-out {
  position: relative;
  text-decoration: none;
}
.strike-out::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: black;
  transform: rotate(-45deg);
}

.PhoneInputInput {
  border-width: 0;
  border-radius: 999999px;
}

.PhoneInputInput:focus {
  border-width: 0 !important;
}
